<template>
  <v-layout>
    <v-app-bar
      color="teal-darken-4"
    >
      <template #image>
        <v-img
          gradient="to top right, rgba(19,84,122,.8), rgba(128,208,199,.8)"
        />
      </template>

      <template #prepend>
        <PIcons 
          p_icon="PhList"
          class="link"
          color="gold"
          size="40"
          @click="show_menu = !show_menu; toTop()"
        />
      </template>
      <div class="w-100">
        <v-app-bar-title>
          <b 
            style="font-size:20px !important; color:gold"
            :style="windowWidth > 400 ? {'letter-spacing': '15px'} : {'letter-spacing': '5px'}"
          >
            Laoabimees
          </b>
        </v-app-bar-title>
      </div>

      <v-spacer />

      <v-btn icon>
        <b style="font-size:15px !important" class="me-4">
          {{ user_row?.kasutaja?.Kasutaja ? user_row?.kasutaja?.Kasutaja : '' }}
        </b>
      </v-btn>
    </v-app-bar>
    
    <v-navigation-drawer
      v-model="show_menu"
      color="#0C1624"
      temporary
      width="300"
      style="
          background: rgba(32, 94, 129, 0.85);
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
          z-index: 10000;
          padding:0;
      "
    >
      <div
        align="left"
        style="font-size: 18px;"
        class="fw-bold text-light m-3"
      > 
        <DivCell align-left="center">
          <template #left>
            <b :style="windowWidth > 400 ? {'letter-spacing': '15px'} : {'letter-spacing': '5px'}">
              Menüü
            </b>
          </template>
          <template #right>
            <PIcons 
              p_icon="PhXCircle"
              color="pink"
              c_class="link"
              @click="show_menu = false"
            />
          </template>
        </DivCell>
      </div>
      <div
        class="p-2"
        align="left"
      >
        <v-list
          v-model:opened="open"
          style="background: transparent; color:white !important;"
        >
          <template
            v-for="link in links"
            :key="link.title"
          >
            <v-list-group
              v-if="link.collapse"
              :value="link.title"
            >
              <template #activator="{ props }">
                <v-list-item
                  v-bind="props"
                  class="border-3 rounded-2 mt-1"
                  :title="link.title"
                >
                  <template #prepend>
                    <PIcons 
                      :p_icon="link.p_icon"
                      size="25"
                      :color="link.p_icon == 'PhFolderSimpleDashed' ? 'bronze' : 'gold'"
                    />
                  </template>
                  <template #append>
                    <div style="margin-right: -1em;">
                      <PIcons 
                        :style="{transform: open.includes(link.title) ? 'rotate(180deg)' : 'rotate(0deg)'}"
                        style="transition: 0.4s;"
                        :p_icon="link.collapse ? 'PhArrowCircleDown' : 'PhArrowCircleRight'"
                        size="25"
                        :color="link.p_icon == 'PhFolderSimpleDashed' ? '#333' : link.collapse ? 'skyblue' : 'lightgreen'"
                      />
                    </div>
                  </template>
                </v-list-item>
              </template>
              <v-list-item 
                v-for="url in link.urls"
                :key="url.title"
                style="padding-inline-start: 10px !important"
                class="border-3 rounded-2 p-2 ms-3 mt-1"
                :title="url.title"
                :class="[url.p_icon == 'PhFolderSimpleDashed' ? 'mt-5 text-warning' : ' text-light', $route.fullPath?.split('/')?.[1] == url.to ? 'border-secondary' : '']"
                @click="url.collapse ? '' : (url.to ? [$router.push(url.to), show_menu = false] : url.funcs(url.func_data))"
              >
                <template #prepend>
                  <PIcons 
                    :p_icon="url.p_icon"
                    size="25"
                    :color="url.p_icon == 'PhFolderSimpleDashed' ? 'bronze' : 'gold'"
                  />
                </template>
                <template #append>
                  <div style="margin-right: -1em;">
                    <PIcons 
                      :p_icon="url.collapse ? 'PhArrowCircleDown' : 'PhArrowCircleRight'"
                      size="25"
                      :color="url.p_icon == 'PhFolderSimpleDashed' ? '#333' : 'lightgreen'"
                    />
                  </div>
                </template>
              </v-list-item>
            </v-list-group>
            <v-list-item 
              v-else
              class="border-3 rounded-2 mt-1"
              :title="link.title"
              :class="[link.p_icon == 'PhFolderSimpleDashed' ? 'mt-5 text-warning' : ' text-light', $route.fullPath?.split('/')?.[1] == link.to ? 'border-secondary' : '']"
              @click="link.collapse ? '' : (link.to ? [$router.push(link.to), show_menu = false] : link.funcs(link.func_data))"
            >
              <template #prepend>
                <PIcons 
                  :p_icon="link.p_icon"
                  size="25"
                  :color="link.p_icon == 'PhFolderSimpleDashed' ? 'bronze' : 'gold'"
                />
              </template>
              <template #append>
                <div style="margin-right: -1em;">
                  <PIcons 
                    :p_icon="link.collapse ? 'PhArrowCircleDown' : 'PhArrowCircleRight'"
                    size="25"
                    :color="link.p_icon == 'PhFolderSimpleDashed' ? '#333' : 'lightgreen'"
                  />
                </div>
              </template>
            </v-list-item>
          </template>
        </v-list>
      </div>
      <template #append>
        <!-- <div>
          <v-btn block color="success">
            Logi sisse
          </v-btn>
        </div> -->
      </template>
    </v-navigation-drawer>
    <v-navigation-drawer
      v-model="showLogin"
      color="#0C1624"
      location="bottom"
      style="height: 350px; padding:0"
      temporary
    > 
      <VCard
        title="Logi sisse"
        p_icon="PhSignIn"
      >
        <div>
          <vuetifyInput 
            v-model="logindata.username"
            type="text"
            label="Kasutajanimi"
          />
          <vuetifyInput 
            v-model="logindata.password"
            type="password"
            label="Parool"
          />
          <v-btn
            color="teal"
            class="mt-2 w-100"
            size="x-large"
            variant="outlined"
            @click="login()"
          >
            Logi sisse
          </v-btn>
        </div>
      </VCard>
    </v-navigation-drawer>
  </v-layout>
</template>
<script setup>
import { ref,reactive } from 'vue';
import VCard from './VCard.vue'
import global from '@/functions/global';
import PIcons from './PIcons.vue'
import vuetifyInput from './vuetifyInput.vue';
import LevelStats from './LevelStats.vue';
import newIcons from './newIcons.vue';
import DivCell from '@/components/parts/DivCell';
const {windowWidth} = global();
const {user_row} = global();
const showLogin = ref(false);
const open = ref([]);

  
const toTop = () => {
      window.scrollTo({
        top: 0,
        behavior: "smooth"
      });
  }


const external = data => {
  window.open('https://viimsi.metsavend.ee/?Lehts=uusgraafikuus', '_self');
}

const openlogin = () => {
  showLogin.value = true;
  show_menu.value = false;
}

const logindata = reactive({username: '', password: ''});


const onlineData = ref();

const show_menu = defineModel();	
defineProps(['links']);
</script>
