<template>
  <div
    style="display: flex;"
    :class="no_style ? '' : 'collapseTitle'"
    :align="align"
    @click="$emit('click')"
  > 
    <div
      :style="reverse ? {} : {'flex-grow': 1}"
      :class="reverse ? 'me-2' : ''"
      :align="alignLeft"
    >
      <div :class="left_middle ? 'divMiddle' : ''">
        {{ left }}
        <slot name="left" />
      </div>
    </div>
    <div
      class="ms-2"
      :style="[{margin: right_valign}, reverse ? {'flex-grow': 1} : {}]"
    >
      <div
        :class="right_middle ? 'divMiddle' : ''"
        :align="alignRight"
      >
        {{ right }}
        <slot name="right" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
    name: 'DivCell'
};
</script>

<script setup>
  defineEmits(['click']);
  defineProps(
    {
        left: {type: [String,Number], default:''},
        right: {type: [String,Number], default:''},
        align:{type:String, default:'left'},
        alignLeft:{type:String, default:'left'},
        alignRight:{type:String, default:'right'},
        no_style:{type:Boolean, default:false},
        right_valign: {type:String, default: 'auto'},
        left_middle: {type:Boolean, default: false},
        right_middle: {type:Boolean, default: false},
        reverse: {type:Boolean, default: false},
    });
</script>

<style>
.divMiddle {
    height:100%;
    display: flex;
    align-items: center;
}
</style>