// import { notify } from "@kyvg/vue3-notification";
import axios from "axios";
// import { useToast } from "primevue/usetoast";
// import { showConfirmDialog, closeNotify, showNotify, showToast } from "vant";
import { computed, getCurrentInstance, reactive, ref } from "vue";
// import { VueCookieNext } from "vue-cookie-next";
// import { useI18n } from "vue-i18n";
import {inject,toRef} from 'vue';
import { useRoute, useRouter } from "vue-router";
// import { user_dataV3 } from "./user_dataV3";
// import { register } from 'register-service-worker'
const user_row = ref({LoggedIn: false, ID: 0});
export default function global(){    
    const data = ref();
    const data_loaded = ref(false);
    // const user_rows = ref({ID:0,Login:false});
    const islogged = ref(0);
    const dev_mode = computed(() => process.env.NODE_ENV  === 'development')
    const is_dev_ip = ref(false);
    const router = useRouter();
    const route = useRoute();
    // const self = getCurrentInstance();
    // const self = getCurrentInstance().proxy;
    // const  { t } = useI18n();
    // const {ID,Session_ID} = user_dataV3();
    // const https = dev_mode.value ? 'https://www.metsavend.ee/metsavend/metsavend_funcs_dev.php' : 'https://www.metsavend.ee/metsavend/metsavend_funcs.php';
    const file_name = getCurrentInstance().type.__name
    // const prime_toast = useToast();

    const windowWidth = ref(window.innerWidth);
    const getDimensions = () => {
        windowWidth.value = window.innerWidth;
    }

    window.addEventListener('resize', getDimensions)

    const actionsheet_style = computed(()=>{
        return windowWidth.value > 600 ? {'max-width': '600px', 'margin-left': windowWidth.value/2-300+'px'} : {}
    })

    const Teated = (row) => {
        if(row){
            if(row.TeateText!='' && row.TeateType!='') {
                showNotify({type: (row.TeateType === 'Error' || row.TeateType === 'error' ? 'danger' : row.TeateType), message: row.TeateText});
                showToast(row.TeateText);
            }
        }
    }

    const floating_alert = text => {
        notify({
            type:text.TeateType,
        title: "Level UP",
        text: text.TeateText,
      });
    }

    const getParent = (name) => {
        if (!name) return false;
        let p = getCurrentInstance()?.parent;
        while (typeof p !== 'undefined') {
            if (p?.type?.__name === name ?? 1 == 2) {
                return p;
            } else {
                p = p?.parent;
            }
        }
        return false;
    }

    const dev = getCurrentInstance()?.type?.__name === 'Metsavend_app' ? getCurrentInstance() : getParent("Metsavend_app");

    const checklogin = async(return_data = false) => {
        data_loaded.value = false;
        return await Request('CheckLogin',{},false,false).then(row => {
            if (row?.userdata?.login) {
                if(process.env.VUE_APP_DEV_IP === row?.userdata?.what_ip) {
                    is_dev_ip.value = true;
                } else {
                    is_dev_ip.value = false;
                }
                user_rows.value = row.userdata;
                islogged.value = true;
                data_loaded.value = true;
                if(return_data){
                    return{user_rows: user_rows.value,islogged:islogged.value}
                }
            }
            else {
                islogged.value = false;
                data_loaded.value = true;
                router.push("/Metsavend");
                if(return_data){
                    return{user_rows: user_rows.value,islogged:islogged.value}
                }
            }
        });
    };

    const removeHTML = text => {
        const div = document.createElement("div");
        div.innerHTML = text;
        return div.textContent || div.innerText || "";
    }


    const devlog = (action,name,req_data,data,error, timetook) => {
        if(process.env.NODE_ENV === 'development' || is_dev_ip.value){
            console.groupCollapsed('%c' + `${name} ${action}`, (error ? 'color:red; background-color:pink' : 'color:Gold;'), `${Number(timetook / 1000).toFixed(2)} s`);
            // console.log(`Filename: @${file_name}`);
            if(req_data){ console.log('%c Request:', 'color:white; background-color:green',req_data)}
            if(data || error){ console.log((error ? '%c Response error:' : '%c Data:'), (error ? 'color:red; background-color:pink' : 'color:white; background-color:blue'), data)}
            console.groupEnd();
        }
    }
  
    const url = "https://laoabimees.metsavend.ee";
    // const Request = async(apiLink) => {
    //     let time = performance.now();
    //     return await axios.get(`${url}/${apiLink}`).then(responsedata => {
    //     devlog(apiLink + '?r=' + Math.random(), 'GET','',responsedata.data, '', performance.now() - time);
    //     if (responsedata.data.text) {
    //         toast({text: responsedata.data.text, success: responsedata.data.success});
    //     }
    //     return responsedata.data;
    // }).catch(err => {
    //     devlog(apiLink + '?r=' + Math.random(), 'GET','', err.response.data, true, performance.now() - time);
    //     toast({text: err.response.data, success: false});
    //     return err
    // })
    // }

    const self = getCurrentInstance();
    const toast = (text, success, timeout = 5000) => {
        if(!self?.root?.exposed?.toast) return;
        return self?.root?.exposed?.toast(text, success, timeout);
    }
    const Request = async(apiLink, data = {}) => {
        // const toast = self?.exposed?.toast;s
        let time = performance.now();
        let promise = '';
        if (data) promise = axios.post(`${url}/${apiLink}`, data ? {...data, session: localStorage.getItem('session')} : '');
        else promise = axios.get(`${url}/${apiLink}`);
        
        return await promise.then((responsedata) => {
            devlog(apiLink + '?r=' + Math.random(), (data ? 'POST' : 'GET'),(data ? {...data, session: localStorage.getItem('session')} : ''),responsedata.data, '', performance.now() - time);
            if (responsedata.data.text) {
                if(!toast) return;
                toast(responsedata.data.text, responsedata.data.success);
                if (responsedata.data.text === 'Sessioon on aegunud') {
                    user_row.value.show_login = true;
                    // localStorage.removeItem('session');
                    // islogged.value = false;
                }
            }
            if (apiLink === 'products/UpdateDB') responsedata.data.timetook = performance.now() - time;
            return responsedata.data;
        })
        // }).catch(err => {
        //     devlog(
        //         apiLink + '?r=' + Math.random(),
        //         (data ? 'POST' : 'GET'),
        //         (data ? {...data, session: sessionStorage.getItem('session')} : ''),
        //         err?.response?.data,
        //         true
        //         , performance.now() - time);
        //     // console.error({err})
        // })
    }

    const UpdateUser = (object = {}) => {
        Request('user/updateData', {data: object});
    }

    const van_dialog = (text,confirm_button_text = 'Kindel',cancel_button_text='Loobu') =>{
        return showConfirmDialog({
            message:text,
            title:'Oled sa kindel',
            confirmButtonColor:'Green',
            confirmButtonText:confirm_button_text,
            cancelButtonColor:'Red',
            cancelButtonText:cancel_button_text,
        })
      }

    const getKeyByValue = (object, value) => {
        return Object.keys(object).find(key => object[key] === value);
    }
                
    const get_time_format = (time) =>{
        if(time>0){
            let tunnid = Math.floor(time / 3600);
            let minutid = Math.floor((time - tunnid*3600) / 60);
            let sekundid = Math.floor((time - tunnid*3600 - minutid * 60))  
            if(time > 3600){
                return `${tunnid} h ${minutid} min ${sekundid} sek`;
            }
            if(time > 59){
                return `${minutid} min ${sekundid} sek`;
            }
                return `${sekundid} sek`;
        }
    }

    const get_date_format = (date,iso) =>{
        let d = new Date(date);
        // if(VueCookieNext.getCookie('language') === 'EST' && !iso){
            return `${String(d.getDate()).padStart(2,'0')}.${String(d.getMonth()+1).padStart(2,'0')}.${d.getFullYear()} ${String(d.getHours()).padStart(2,'0')}:${String(d.getMinutes()).padStart(2,'0')}:${String(d.getSeconds()).padStart(2,'0')} `
        // }else{
        //    return `${d.getFullYear()}-${String(d.getMonth()+1).padStart(2,'0')}-${String(d.getDate()).padStart(2,'0')} ${String(d.getHours()).padStart(2,'0')}:${String(d.getMinutes()).padStart(2,'0')}:${String(d.getSeconds()).padStart(2,'0')} `
        // }
    }

    const count = data => {
        if(data !== undefined){
            if(typeof data === 'object' && data !== null){
                return Object.keys(data).length;
            }else if((Array.isArray(data) || typeof data === 'string') && data !== null ){
                return data.length;
            }else{
                return 0;
            }
        } else {
            return 0;
        }
    }

    const formControll = (data, checkArray = [], specificControl = {}) => {
        let error = false;
        for (const [key, value] of Object.entries(data)) {
          if (key in specificControl) {
            for (const [key, fieldData] of Object.entries(specificControl)) {
              if (fieldData[0]) {
                error = true;
                Teated({TeateType:'danger', TeateText:fieldData[1] ? fieldData[1] : key });
                return error;
              }
            }
          }
          if (checkArray.length) {
            if (key in checkArray){
              if (Array.isArray(value) ? !count(value) : true && !value){
                error = true;
                Teated({TeateType:'danger', TeateText:t('Metsavend.Alerts.FillAll')});
              } 
            } 
          } else {
            if (Array.isArray(value) ? !count(value) : true && !value && key !== 'no_item_id_file_hash') {
              error = true;
              Teated({TeateType:'danger', TeateText:t('Metsavend.Alerts.FillAll')});
            }
          }
        }
        return error;
    }

    const OldUTF8 = text => {
        if (text) {
            return text.replace(/Ã¼/g,'ü').replace(/Ãœ/g, 'Ü').replace(/mÂ²/g, 'm²').replace(/Ã/g, 'Ü').replace(/Ã¤/g,'ä').replace(/Ãµ/g,'õ').replace(/Ã¶/g,'ö').replace(/â¬/g, '€').replace(/Ã/g, 'Õ').replace(/Ã/g, 'Ä').replace(/&otilde;/g, 'õ').replace(/&Otilde;/g,'Õ');
        } else {
            return '';
        }
    }

    const copy = data => {
        const tmpTextField = document.createElement("textarea")
        tmpTextField.textContent = data
        tmpTextField.setAttribute("style","position:absolute; right:200%;")
        document.body.appendChild(tmpTextField)
        tmpTextField.select()
        tmpTextField.setSelectionRange(0, 99999) /*For mobile devices*/
        document.execCommand("copy")
        tmpTextField.remove()
        Teated({TeateText:"Edukalt kopeeritud: '" + data + "'", TeateType:'success'});
    }
    const toTop = () => {
        window.scrollTo({
          top: 0,
          behavior: "smooth"
        });
    }

    const calculateDate = seconds => {
        var d = Math.abs(seconds);                           // delta
        var r = {};                                                                // result
        var s = {
            kuu: 2592000,
            nadal: 604800, // uncomment row to ignore
            paev: 86400,   // feel free to add your own row
            tund: 3600,
            minut: 60,
            sekund: 1
        };

        Object.keys(s).forEach(function(key){
            r[key] = Math.floor(d / s[key]);
            d -= r[key] * s[key];
        });

        let string = "";
        if (r.kuu > 0) string = string + `${r.kuu} ${r.kuu > 1 ? 'kuud' : 'kuu'} `;
        if (r.nadal > 0) string = string + `${r.nadal} ${r.nadal > 1 ? 'nädalat' : 'nädal'} `;
        if (r.paev > 0) string = string + `${r.paev} ${r.paev > 1 ? 'päeva' : 'päev'} `;
        if (r.tund > 0) string = string + `${r.tund} ${r.tund > 1 ? 'tundi' : 'tund'} `
        if (r.minut > 0) string = string + `${r.minut} ${r.minut > 1 ? 'minutit' : 'minut'} `
        if (r.sekund > 0) string = string + `${r.sekund} ${r.sekund > 1 ? 'sekundit' : 'sekund'} `

        return string;
    }

    const KT_logi = (type,kasutaja,vastamisaeg) =>{
        Request('KT_Logi',{
            action:'KT_Logi',
            Type:type,
            Kasutaja: kasutaja,
            Aeg:Math.floor(new Date().getTime()/1000),
            Vastamisaeg: vastamisaeg,
        })
    }

    const Klick_log = (text,kasutajanimi) =>{
        Request('Klick_log',{
            Text:text,
            Kasutaja: kasutajanimi,
            Aeg:Math.floor(new Date().getTime()/1000),
        })
    }
    const sendNotification = data => {
        Notification.requestPermission().then((result) => {
            if (result === "granted") {
                navigator.serviceWorker.ready.then((registration) => {
                    registration.showNotification(data.title, {
                    body: data.message,
                    icon: "https://www.metsavend.ee/favicon.ico",
                    badge: "https://www.metsavend.ee/favicon.ico",
                    image: "https://www.metsavend.ee/favicon.ico",
                    vibrate: [200, 100, 200, 100, 200, 100, 200],
                    tag:data.title,
                    });
                });
                }
        });
    }
    const isMobile = () => {
        return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    };

    const map = async(data) => {
        if(typeof data === 'object' && data !== null){
            return await Object.entries(data ?? {})
        } else {
            return await data
        }
    }

    const https = 'https://www.metsavend.ee/laoabimees/backend/routes.php';
    
    const RequestPHP = async(action,data,user_request,no_dev) =>{
        let timeStart = performance.now();
        data_loaded.value = false;
        let data_new = {...data};
        let data_req = {action:action,session:localStorage.getItem('session'), ...data_new}
        return axios.post(https+'?request&r='+Math.random(),{
            action:action,
            session:localStorage.getItem('session'),
            ...data_new,
        }).then(row =>{
            if(row?.data?.TeateType && row?.data?.TeateText){
                if(!toast) return;
                toast(row.data.TeateText, row.data.TeateType == 'success');
                // prime_toast.add({severity:row?.data?.TeateType, summary:'House upgrade', detail:row?.data?.TeateText, life:7000})
            }
            if(!no_dev || true){ 
                devlog(action,'Request',data_req,row.data, '', performance.now() - timeStart);
                if(dev?.refs?.dev_data) dev.refs.dev_data.data[`Request: ${action} @${file_name}`] = {data:row.data, Request:data_req}
            }
            data_loaded.value = true;
            return row.data;
        }).catch(error=>{
            devlog(action,'Request-Error',data_req,(error?.response?.data ?? error).data);
            console.log('%c Request Error ', 'color:white; background-color:red',(error?.response?.data ?? error));
            return toast(error.code, false);
        })
    }

    return {
        isMobile,
        map,
        RequestPHP,
        sendNotification,
        KT_logi,
        Klick_log,
        calculateDate,
        toTop,
        copy,
        count,
        OldUTF8,
        formControll,
        getKeyByValue,
        get_date_format,
        get_time_format,
        van_dialog,
        checklogin,
        is_dev_ip,
        Request,
        // t,
        toast,
        UpdateUser,
        removeHTML,
        windowWidth,
        floating_alert,
        data,
        data_loaded,
        route,
        router,
        Teated,
        user_row,
        dev_mode,
        actionsheet_style,
        islogged,
        getParent,
    }
}