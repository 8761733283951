<template>
  <v-card
    class="mb-3"
    :elevation="2"
    :style="noBg ? {} : {
      background: 'rgba(52, 94, 130, 0.95)'
    }"
  >
    <template #title>
      <div
        v-if="dataloaded"
        class="pb-1"
        :style="windowWidth > 400 ? {'letter-spacing': '5px'} : {'letter-spacing': '2px'}"
        style="font-size: 14px !important; font-weight: bold; color: gold; border-bottom: 1px solid #85CE36;"
        @click="reloadfunc ? reloadfunc() : ''"
      >
        <PIcons 
          v-if="p_icon"
          :p_icon="p_icon"
          color="#333"
          :size="25"
        />
        <newIcons
          v-if="svg"
          :svg="svg"
          size="25"
          class="me-2"
        />
        <span 
          v-if="title"
          style="white-space: normal"
        >
          {{ title }}
        </span>
        <slot name="title" />
      </div>
      <div
        v-else
        style="border-bottom: 1px solid skyblue;"
      >
        <PIcons 
          p_icon="PhDatabase"
          :size="25"
          color="#333"
          :c_class="''"
        />
        Andmete laadimine
      </div>
    </template>
    <v-card-text v-if="height" :style="{height: height}">
        <slot />
    </v-card-text>
    <template #text v-if="!height">
      <div
        v-if="dataloaded"
        align="left"
      >
        <newIcons 
          v-if="svg_big"
          style="position: absolute; opacity:0.15; left: 50%;
          transform: translate(-50%, 0);"
          :svg="svg_big"
          size="200"
        />
        <slot />
      </div>
      <div v-else>
        <v-progress-linear
          indeterminate
          color="primary"
          height="30"
        />
      </div>
    </template>
  </v-card>
</template>
<script setup>
import global from '@/functions/global';
import PIcons from './PIcons.vue';
import newIcons from '@/components/parts/newIcons';
const {windowWidth} = global();
    defineProps({
        title: {type:String},
        p_icon: {type:String},
        svg: {type:String},
        svg_big: {type:String},
        height: {type:String},
        noBg: {type: Boolean, default: false},
        dataloaded: {type: Boolean, default: true},
        reloadfunc: {type: Function, default: () => {}}
    })
</script>
