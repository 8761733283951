
import { createRouter, createWebHashHistory } from 'vue-router'

export default new createRouter({
    history: createWebHashHistory(),
    base: __dirname,
    routes: [
        { path: "/:pathMatch(.*)*",component: () => import("@/components/NotFound.vue"), meta:{m2ng:'Laoabimees',title:'NotFound', loggin: false}},
        {path:'/Login', component: () => import("@/components/V2linePealeht.vue"), meta:{m2ng:'Laoabimees',title:'Logi sisse', loggin: false}},
        {path:'/', component: () => import("@/components/V2linePealeht.vue"), meta:{m2ng:'Laoabimees',title:'Pealeht', loggin: false}},
        {path:'/Graafik', component: () => import("@/components/TooGraafik.vue"), meta:{m2ng:'Laoabimees',title:'Töögraafik', loggin: true}},
        {path:'/LisaSaatelehti', component: () => import("@/components/LisaSaatelehti.vue"), name: 'LisaSaatelehti', props: true, meta:{m2ng:'Laoabimees',title:'Saatelehtede lisamine', loggin: true}},
        {path:'/ToodeteDBUuendus', component: () => import("@/components/ToodeteDBUuendus.vue"), meta:{m2ng:'Laoabimees',title:'Toodete DB uuendus', loggin: true}},
        {path:'/Vedajad', component: () => import("@/components/VedajateLisamine.vue"), meta:{m2ng:'Laoabimees',title:'Vedajate lisamine', loggin: true}},
        {path:'/AlusteKogused', component: () => import("@/components/AlusteKogused.vue"), meta:{m2ng:'Laoabimees',title:'Aluste kogused', loggin: true}},
        {path:'/LaoLiikumised', component: () => import("@/components/LaoLiikumised.vue"), meta:{m2ng:'Laoabimees',title:'Laoliikumised', loggin: true}},
        {path:'/Idaring', component: () => import("@/components/IdaRing.vue"), props: true, meta:{m2ng:'Laoabimees',title:'Idaring', loggin: true}},
        {path:'/KoikSautelehed', component: () => import("@/components/KoikSautelehed.vue"), props: true, meta:{m2ng:'Laoabimees',title:'Sisestatud saatelehed', loggin: true}},
        {path:'/PoolikInventuur', component: () => import("@/components/PoolikInventuur.vue"), props: true, meta:{m2ng:'Laoabimees',title:'Poolik Inventuur', loggin: true}},
        {path:'/TaisInventuur', component: () => import("@/components/TaisInventuur.vue"), props: true, meta:{m2ng:'Laoabimees',title:'Täisinventuur', loggin: true}},
        {path:'/InventuuriLogi', component: () => import("@/components/InventuuriLogi.vue"), props: true, meta:{m2ng:'Laoabimees',title:'Täisinventuuri logi', loggin: true}},
        {path:'/T2htsadSonad', component: () => import("@/components/T2htsadSonad.vue"), props: true, meta:{m2ng:'Laoabimees',title:'Tähtsad sõnad', loggin: true}},

    ],
})
