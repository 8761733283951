<template>
  <div
    :style="noborder ? '' : 'border-bottom: 1px dashed;'"
    :class="c_class ? c_class : 'p-2'"
    @click="$emit('click')"
  >
    <DivCell>
      <template #left>
        <div
          class="fw-bold"
          :style="textClassLeft ? '' : 'color:#CAF1DE;'"
          :class="textClassLeft"
          @click="$emit('leftClick')"
        >
          <slot name="left" />
          {{ left }}
        </div>
      </template>
      <template #right>
        <div
          :style="textClassRight ? '' : 'color:#CAF1DE;'"
          :class="textClassRight"
          @click="$emit('rightClick')"
        >
          <slot name="right" />
          {{ right }}
        </div>
      </template>
    </DivCell>
    <DivCell class="mt-1">
      <template #left>
        <div
          class="fw-bold;"
          style="color:gold; font-size: 80%"
          :class="textClassLeftSmall"
        >
          <slot name="small_left" />
          {{ small_left }}
        </div>
      </template>
      <template #right>
        <div
          style="color:yellow; font-size: 80%"
          :class="textClassRightSmall"
        >
          <slot name="small_right" />
          {{ small_right }}
        </div>
      </template>
    </DivCell>
  </div>
</template>

<script setup>
    import DivCell from './DivCell.vue';
    defineProps(['left','right','small_left','small_right','noborder', 'c_class', 'textClassRight', 'textClassLeft', 'textClassLeftSmall', 'textClassRightSmall']);
    defineEmits(['rightClick', 'leftClick', 'click']);
</script>
<script>
export default {
    name: 'LevelStats',
};
</script>

<style lang="scss" scoped>

</style>