import { createApp, provide } from 'vue';
import BootstrapVueNext from 'bootstrap-vue-next';
import App from './App.vue';
import router from './router';
import axios from 'axios'
import VueAxios from 'vue-axios'
import '@mdi/font/css/materialdesignicons.css'
import 'vuetify/styles'
import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'
import "primevue/resources/themes/fluent-light/theme.css"
import PrimeVue from 'primevue/config';
const vuetify = createVuetify({
  components,
  directives
})
import JSONView from 'vue-json-component-vue-3';
import "vue-json-component-vue-3/dist/style.css";

import contextmenu from "v-contextmenu";
import "v-contextmenu/dist/themes/dark.css";

import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap"

const app = createApp(App);
const toast = (text, color, timeout = 5000) =>{
  snackbar.show = ref(true);
  snackbar.text = ref(text);
  snackbar.color = ref(color);
  snackbar.timeout = ref(timeout);
}
provide('toast', toast);

app.use(JSONView);
app.use(router);
app.use(contextmenu);
app.use(PrimeVue);
app.use(vuetify);
app.use(VueAxios, axios)
app.use(BootstrapVueNext);
app.mount('#app');
