<template>
  <div
    v-if="typeArray.includes(type)"
    class="w-100"
    :class="type !== 'select' ? 'mt-4' : ''"
  >
    <div
      v-if="label?.length > maximumsize()"
    >
      <PIcons
        v-if="required"
        :p_icon="value && !error ? 'PhCheckCircle' : 'PhAsterisk'"
        :size="value && !error ? '20' : '15'"
        :color="value && !error ? 'lightgreen' : 'red'"
        :c_class="'me-1'"
      />
      {{ label }}
    </div>
    <component 
      :is="components[type]"
      v-model="value"
      style="background-color: transparent !important"
      :style="[type === 'hidden' ? {display: 'none'} : {}, {color: color}, {border: `${type === 'button' ? '1px solid silver' : ''}`}]"
      :clearable="readonly || info ? false : clearable"
      :type="type"
      auto-grow
      :rows="rows"
      :counter="counter ? true : false"
      hide-details="auto"
      persistent-clear
      :name="name"
      :placeholder="label?.length > 25 ? 'Sisesta vastus siia' : undefined"
      :readonly="type === 'hidden' || readonly || info"
      :disabled="disabled"
      :density="density"
      :size="type === 'button' ? 'x-large' : ''"
      :dirty="!!value ? true : false"
      :required="overwriteRequire ? !overwriteRequire : required"
      :variant="type === 'button' ? 'tonal' : 'outlined'"
      :rules="!error || readonly || info ? [value => true] : (error || (required && !overwriteRequire) ? [value => (custom_rule ? !custom_rule : !!value) || (rule_text ? (custom_rule ? rule_text : (value ? '' : 'See väli on nõutud')) : 'See väli on nõutud')] : [])"
      :hint="hint"
      :no-data-text="'Andmeid pole'"
      theme="dark"
      :block="block"
      autocomplete="off"
      v-bind="selected_items"
      :class="info ? 'bg-light' : undefined"
      @update:modelValue="$emit('change', $event);"
      @click="$emit('click')"
      @keyup.enter="$emit('enter')"
      @blur="$emit('blur')"
      @click:clear="$emit('clear')"
    >
      <span
        v-if="type === 'button'"
      >
        <PIcons
          v-if="left_p_icon"
          size="25"
          :p_icon="left_p_icon"
          :color="type && !left_p_icon_color === 'button' ? '#7FFFD4' : left_p_icon_color"
          :c_class="''"
        />
        <newIcons 
          v-if="svg"
          size="25"
          :svg="svg"
        />
        {{ label }}
      </span>
      <template
        v-if="label?.length <= maximumsize()"
        #label
      >
        <PIcons
          v-if="required"
          :p_icon="value && !error ? 'PhCheckCircle' : 'PhAsterisk'"
          :size="value && !error ? '20' : '15'"
          :color="value && !error ? 'lightgreen' : 'red'"
          :c_class="'me-1'"
        />
        {{ label }}
      </template>
      <template #append-inner>
        <slot name="append-inner" />
        <PIcons
          v-if="p_icon"
          :p_icon="p_icon"
          :size="20"
          :color="p_icon_color"
          :c_class="'me-1'"
        />
        <!-- <Tooltip
                    v-if="floating_title"
                    :p_icon="'PhQuestion'"
                    :text="floating_title"
                /> -->
      </template>
      <template
        #prepend-inner
      >
        <slot name="prepend-inner" />
        <div style="margin-right: -0.1em;">
          <PIcons
            v-if="left_p_icon"
            size="25"
            :p_icon="left_p_icon"
            :color="left_p_icon_color"
            :c_class="''"
          />
          
          <newIcons 
            v-if="svg"
            size="25"   
            :svg="svg" 
          />
        </div>
      </template>
    </component>
  </div>
  <div v-else>
    <VAlert
      :type="'danger'"
      :text="'Type on defineerimata'"
    />
  </div>
</template>

<script setup>  
    import { ref, computed } from 'vue';
    import PIcons from './PIcons.vue';
    import VAlert from './VAlert.vue';
    import global from '../../functions/global.js'
import newIcons from '@/components/parts/newIcons';

    const {windowWidth} = global();
    const value = defineModel();	
    const props = defineProps({
        type: {type: String, default: ''},
        name: {type: [String, Number], default: ''},
        label: {type: String, default: ''},
        rows: {type: Number, default: 5},
        readonly: {type: Boolean, default: false},
        color: {type: String, default:'white'},
        density: {type: String, default:'default'},
        info: {type: Boolean, default: false},
        block: {type: Boolean, default: true},
        disabled: {type: Boolean, default: false},
        p_icon: {type: String, default: ''},
        svg: {type: String, default: ''},
        p_icon_color: {type: String, default: '#333'},
        left_p_icon: {type: String, default: ''},
        left_p_icon_color: {type: String, default: '#333'},
        hint: {type: String, default: ''},
        rule_text: {type: String, default: ''},
        custom_rule: {type: Array, default: () => []},
        required: {type: Boolean, default: false},
        error: {type: Boolean, default: false},
        counter: {type: Boolean, default: false},
        clearable: {type: Boolean, default: true},
        items: {type: [Array], default: () => [] },
        itemTitle: {type: String, default: ''},
        itemValue: {type: String, default: '',},
        floating_title: {type: String, default:''}
    });
    defineEmits(['change','click','focusout','blur', 'keyup', 'focus','clear','enter']);
    const maximumsize = () => {
        if (windowWidth.value < 500) return 33;
        else if (windowWidth.value <= 600 && windowWidth.value > 500) return 50;
        else return 80;
    }
    const overwriteRequire = ref(false);
    overwriteRequire.value = !!props.value;
    const typeArray = ['text','textarea','number','select','password','time','hidden','email', 'button', 'combobox','checkbox'];

    const selected_items = computed(() => {
        let select = {};
        if (props.items.length > 0) {
            select.items = props.items;
            if (props.itemTitle) {
                select.itemTitle = props.itemTitle;
            }
            if(select.itemTitle || select.itemValue) select.returnObject = true;
            else select.returnObject = false;

            if (props.itemValue) select.itemValue = props.itemValue;
            return {...select}
        }
        if (props.type === 'button') select.block = true;
        return {}
    })

    import { VNumberInput } from 'vuetify/labs/VNumberInput'


    const components = {
        'number': VNumberInput,
        'text': 'v-text-field',
        'textarea': 'v-textarea',
        'number': 'v-text-field',
        'time': 'v-text-field',
        'select': 'v-autocomplete',
        'combobox': 'v-combobox',
        'password': 'v-text-field',
        'hidden': 'v-text-field',
        'email': 'v-text-field',
        'button': 'v-btn',
        'checkbox': "v-checkbox"
    }
</script>