<template>
  <div id="app">
    <span class="bg" />
    <navBar 
      v-model="show_menu"
      style=" margin-top: 5em;"
      :links="links"
    />

    <v-container
      v-if="user_row.LoggedIn"
      fluid
      style="width:100%"
    >
      <v-row
        v-if="$route.meta?.loggin === user_row.LoggedIn || !$route.meta?.loggin"
        no-gutters
      >
        <v-col v-if="windowWidth > 1000">
          <div
            style="300px"
            class="px-3"
          >
            <VCard 
              :title="'Menüü'"
              :dataloaded="true"
            >
              <v-list
                v-model:opened="open"
                style="background: transparent; color:white;"
              >
                <template
                  v-for="link in links"
                  :key="link.title"
                >
                  <v-list-group
                    v-if="link.collapse"
                    :value="link.title"
                  >
                    <template #activator="{ props }">
                      <v-list-item
                        v-bind="props"
                        class="border-3 rounded-2 mt-1"
                        :title="link.title"
                      >
                        <template #prepend>
                          <PIcons 
                            :p_icon="link.p_icon"
                            size="25"
                            :color="link.p_icon == 'PhFolderSimpleDashed' ? 'bronze' : 'gold'"
                          />
                        </template>
                        <template #append>
                          <div style="margin-right: -1em;">
                            <PIcons 
                              :style="{transform: open.includes(link.title) ? 'rotate(180deg)' : 'rotate(0deg)'}"
                              style="transition: 0.4s;"
                              :p_icon="link.collapse ? 'PhArrowCircleDown' : 'PhArrowCircleRight'"
                              size="25"
                              :color="link.p_icon == 'PhFolderSimpleDashed' ? '#333' : link.collapse ? 'skyblue' : 'lightgreen'"
                            />
                          </div>
                        </template>
                      </v-list-item>
                    </template>
                    <v-list-item 
                      v-for="url in link.urls"
                      :key="url.title"
                      style="padding-inline-start: 10px !important"
                      class="border-3 rounded-2 p-2 ms-3 mt-1"
                      :title="url.title"
                      :class="[url.p_icon == 'PhFolderSimpleDashed' ? 'mt-5 text-warning' : ' text-light', $route.fullPath?.split('/')?.[1] == url.to ? 'border-secondary' : '']"
                      @click="url.collapse ? '' : (url.to ? [$router.push(url.to), show_menu = false] : url.funcs(url.func_data))"
                    >
                      <template #prepend>
                        <PIcons 
                          :p_icon="url.p_icon"
                          size="25"
                          :color="url.p_icon == 'PhFolderSimpleDashed' ? 'bronze' : 'gold'"
                        />
                      </template>
                      <template #append>
                        <div style="margin-right: -1em;">
                          <PIcons 
                            :p_icon="url.collapse ? 'PhArrowCircleDown' : 'PhArrowCircleRight'"
                            size="25"
                            :color="url.p_icon == 'PhFolderSimpleDashed' ? '#333' : 'lightgreen'"
                          />
                        </div>
                      </template>
                    </v-list-item>
                  </v-list-group>
                  <v-list-item 
                    v-else
                    class="border-3 rounded-2 mt-1"
                    :title="link.title"
                      :class="[link.p_icon == 'PhFolderSimpleDashed' ? 'mt-5 text-warning' : ' text-light', $route.fullPath?.split('/')?.[1] == link.to ? 'border-secondary' : '']"
                    @click="link.collapse ? '' : (link.to ? [$router.push(link.to), show_menu = false] : link.funcs(link.func_data))"
                  >
                    <template #prepend>
                      <PIcons 
                        :p_icon="link.p_icon"
                        size="25"
                        :color="link.p_icon == 'PhFolderSimpleDashed' ? 'bronze' : 'gold'"
                      />
                    </template>
                    <template #append>
                      <div style="margin-right: -1em;">
                        <PIcons 
                          :p_icon="link.collapse ? 'PhArrowCircleDown' : 'PhArrowCircleRight'"
                          size="25"
                          :color="link.p_icon == 'PhFolderSimpleDashed' ? '#333' : 'lightgreen'"
                        />
                      </div>
                    </template>
                  </v-list-item>
                </template>
              </v-list>
            </VCard>
          </div>
        </v-col>
        <v-col>
          <div
            :style="{width: windowWidth > 1000 ? Number(windowWidth - 350) + 'px' : '100%'}"
          >
            <router-view />
          </div>
        </v-col>
      </v-row>
    </v-container>
    <v-dialog
      v-if="!user_row.LoggedIn || !sessionHas || user_row.show_login"
      v-model="dialog"
      persistent
      width="auto"
    >
      <VCard
        title="Jätkamiseks pead ennem sisselogima"
        svg="login"
      >
        <div>
          <VuetifyInput 
            v-model="username"
            type="text"
            label="Kasutajanimi"
            left_p_icon="PhUserCircle"
            @enter="login()"
          />
          <VuetifyInput 
            v-model="password"
            type="password"
            label="Parool"
            left_p_icon="PhPassword"
            @enter="login()"
          />
        <DivCell>
            <template #left>
                <VuetifyInput 
                    v-model="rembember_me"
                    type="checkbox"
                    label="Jäta mind meelde"
                />
            </template>
        </DivCell>
          <VuetifyInput 
            type="button"
            label="Logi sisse"
            left_p_icon_color="#333"
            left_p_icon="PhSignIn"
            @click="login()"
          />
        </div>
      </VCard>
    </v-dialog>
    <v-dialog
      v-model="loadingModal"
      :scrim="true"
      persistent
      width="auto"
    >
      <v-card
        color="darkorange"
      >
        <v-card-text>
          <div
            align="center"
            style="width: 300px; height: 100px;"
          >
            <div
              class="fw-bold mb-3"
              style="color: gold; font-size: 16px"
            >
              Palun oota. Andmete laadimine
            </div>
              
            <v-progress-circular
              indeterminate
              :size="70"
              :width="7"
              color="info"
              class="mb-0 d-block"
            />
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-snackbar
      v-model="snackbar.show"
      :timeout="snackbar.timeout"
      :color="snackbar.color"
      location="top"
    >
      {{ snackbar.text }}
      <template #actions>
        <PIcons 
          p_icon="PhXCircle"
          size="30"
          class="link"
          color="pink"
          @click="snackbar.show = false"
        />
      </template>
    </v-snackbar>
  </div>
</template>

<script setup>
import { ref, reactive, inject, watch } from 'vue';
import navBar from './components/parts/nav_bar.vue';
import PIcons from './components/parts/PIcons.vue';
import VCard from './components/parts/VCard.vue';
import { provide, toRefs } from 'vue';
import global from './functions/global';
import newIcons from '@/components/parts/newIcons'
import DivCell from './components/parts/DivCell.vue';
import VuetifyInput from './components/parts/vuetifyInput.vue';
import axios from 'axios';
const {router,route,  Request, windowWidth, user_row} = global();
const open = ref([]);
const sessionHas = ref(!!localStorage.getItem('session'));
const userdata = ref({});
const crypt = data => btoa(`${data}`);
const decrypt = data => atob(data);
const username = ref(localStorage.getItem('saved') ? decrypt(JSON.parse(localStorage.getItem('saved')).user) : '');
const password = ref(localStorage.getItem('saved') ? decrypt(JSON.parse(localStorage.getItem('saved')).pwd) : '');

const show_menu = ref(false);
const rembember_me = ref(localStorage.getItem('saved') ? true : false);
const loading = ref(0);
// need lähevad eraldi faili
const login = () => {
  if(username.value.length > 0 && password.value.length > 0) {
    localStorage.removeItem('session');
    Request(`user/login`, {username: username.value, password: password.value}).then(responseData => {
      if (responseData.success) {
        if (rembember_me.value) {
              localStorage.setItem('saved', 
                  JSON.stringify({
                      user: crypt(username.value),
                      pwd: crypt(password.value)
              }));
          } else {
              localStorage.removeItem('saved');
          }
          if (!user_row.value.show_login) router.push('/Graafik');
        localStorage.setItem('session', responseData.Session);
        user_row.value = {...responseData, Session: localStorage.getItem('session')};
        delete user_row.value.success;
        sessionHas.value = true;
        delete user_row.value.text;
      }
    })
  }
}



const external = data => {
  window.open('https://viimsi.metsavend.ee/?Lehts=uusgraafikuus', '_self');
}
const links = 
  [
    { 
      title: 'Inventuur', 
      p_icon: 'PhWarehouse',
      collapse: true,
      urls: [
        {
          title: 'Poolik inventuur',
          p_icon: 'PhPackage',
          to: 'PoolikInventuur',
          group:'Inventuur'
        },
        {
          title: 'Täisinventuur',
          p_icon: 'PhPackage',
          to: 'TaisInventuur',
          group:'Inventuur'
        },
        {
          title: 'Täisinventuuri logi',
          p_icon: 'PhList',
          to: 'InventuuriLogi',
          group:'Inventuur'
        }
    ]},
    { 
      title: 'Saatelehed', 
    p_icon: 'PhFiles',
    collapse: true,
    urls: [
      {
        title: 'Saatelehtede lisamine',
        p_icon: 'PhFolderPlus',
        to: 'LisaSaatelehti',
        group:'Lehed'
      },
      {
        title: 'Idaring',
        p_icon: 'PhGlobeHemisphereWest',
        to: 'IdaRing',
        group:'Lehed'
      },
      {
        title: 'Laoliikumised',
        p_icon: 'PhArrowsLeftRight',
        to: 'LaoLiikumised',
        group:'Lehed'
      },
  ]}
  ,
  { 
    title: 'Seaded', 
    p_icon: 'PhGear',
    collapse: true,
    urls: [
      {
        title: 'Toodete DB uuendus',
        p_icon: 'PhDatabase',
        to: 'ToodeteDBUuendus',
        group:'Options'
      },
      {
        title: 'Vedajad',
        p_icon: 'PhTruck',
        to: 'Vedajad',
        group:'Options'
      },
      {
        title: 'Aluste kogused',
        p_icon: 'PhCube',
        to: 'AlusteKogused',
        group:'Options'
      },
      {
        title: 'Tähtsad sõnad',
        p_icon: 'PhInfo',
        to: 'T2htsadSonad',
        group:'Options'
      },
      // {
        //   title: 'Sisestatud saatelehed',
      //   p_icon: 'PhListBullets',
      //   to: 'KoikSautelehed',
      //   group:'Lehed'
      // },
    ]
  },
  {
  title: 'Töögraafik',
  p_icon: 'PhChartScatter',
  to: 'Graafik'
},
  // {
  //   title: 'Vana rakendus',
  //   p_icon: 'PhFolderSimpleDashed',
  //   funcs: external,
  //   func_data: 'https://viimsi.metsavend.ee/?Lehts=uusgraafikuus'
  // },
]

const checkLoginLastCheck = ref(0);
let timeout = null;
const checkLogin = () => {
  if (!!localStorage.getItem('session')) {  
    if (checkLoginLastCheck.value + 60000 < new Date().getTime()) {
      Request('user/checkLogin').then(responseData => {
        if(responseData.LoggedIn) {
          checkLoginLastCheck.value = new Date().getTime();
          user_row.value = {...responseData, Session: localStorage.getItem('session'), sdasds: 'dsaadssda'};
          // delete user_row.value.success;
          sessionHas.value = true;
          // delete user_row.value.text;
        } else {
          localStorage.removeItem('session');
          router.push('/')
          sessionHas.value = false;
        }
      })
    }
  } else {
    router.push('/')
    checkLoginLastCheck.value = new Date().getTime();
    sessionHas.value = false;
  }
}
checkLogin();
const snackbar = reactive({
  show: false,
  text: '',
  color: '',
  timeout: 5000
});
const toast = (text, success, timeout = 5000) =>{
  snackbar.show = ref(true);
  snackbar.text = ref(text);
  snackbar.color = ref(success ? 'green' : 'red');
  snackbar.timeout = ref(timeout);
}
defineExpose({toast})


document.title = `Laoabimees`;
provide('loading', loading);

router.afterEach((to, from) => {
  document.title = `Laoabimees - ${to.meta.title}`;
  if (user_row.value.LoggedIn && !to.meta.loggin) {
    router.push('/Graafik');
  }
});

const loadingModal = ref(false);
const checkloading = () => {
  if (loading.value > 0) {
    loadingModal.value = true;
    return;
  }
  
  if (loading.value === 0) { 
    setTimeout(() => {
      loadingModal.value = false;
    }, 100);
  }
}

axios.interceptors.request.use(request => {
  if(!request.data?.noLoading && request?.url.split('/').pop() != 'checkLogin') loading.value++;
  checkloading();
  return request;
})
axios.interceptors.response.use(response => {
  loading.value--;
  if (loading.value < 0) loading.value = 0;
  checkloading();
  return response;
})

let fontsize = '12px';

if (windowWidth.value < 500) {
  fontsize = '10px'
} else{
  fontsize = '11px'
}

const dialog = ref(!user_row.value.LoggedIn)
router.afterEach(() => {
  if (checkLoginLastCheck.value + 60000 > new Date().getTime()) {
    checkLogin();
  }
})
let saatelehtedeLehed = [
  {group: 'Inventuur', lehed: ['PoolikInventuur','TaisInventuur', 'TäisInvMahaarvestus', 'InventuuriLogi']},
  {group: 'Saatelehed', lehed: ['LisaSaatelehti','IdaRing', 'LaoLiikumised']},
  {group: 'Seaded', lehed: ['ToodeteDBUuendus', 'Vedajad', 'AlusteKogused']}
]
watch(() => route.fullPath, newvalue => {
  if (saatelehtedeLehed[1].lehed.includes(newvalue?.split('/')[1])) {
    open.value.push('Saatelehed')
  }
  else if (saatelehtedeLehed[2].lehed.includes(newvalue?.split('/')[1])) {
    open.value.push('Seaded')
  }
  else if (saatelehtedeLehed[0].lehed.includes(newvalue?.split('/')[1])) {
    open.value.push('Inventuur')
  }
})

watch(() => user_row.value.show_login, newvalue => {
  if (newvalue) dialog.value = true;
})
</script>

<style>

@font-face {
    font-family: jetbrains;
    src: url("assets/fonts/jetbrains-mono-latin-400-normal.f6a49a98.woff2");
}
.bg {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
#app {
  /* font-family: Avenir, Helvetica, Arial, sans-serif; */
  font-family: jetbrains !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  color: #2c3e50;
}
body{
  background:#0C1624;
  font-size:10px !important;
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  height: 100%;
  background-image: linear-gradient(rgba(	44, 62, 80, 0.5), rgba(	44, 62, 80,0.7)),   url("../src/assets/wp3704388.jpg");
  background-repeat: no-repeat !important;
  background-attachment: fixed !important;
  background-size: cover !important;
}

* {
  font-size: 16px !important;
  font-family: jetbrains !important;
}
nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}

.link {
  opacity: 1;
}

.link:hover {
  opacity: 0.6;
  cursor:pointer;
}
*{
  font-size:v-bind(fontsize) !important;
}
.pre {
 white-space: pre-wrap;       /* css-3 */
 white-space: -moz-pre-wrap;  /* Mozilla, since 1999 */
 white-space: -pre-wrap;      /* Opera 4-6 */
 white-space: -o-pre-wrap;    /* Opera 7 */
 word-wrap: break-word;       /* Internet Explorer 5.5+ */
}
.v-list-group__items .v-list-item  {
  padding-inline-start: 5px !important;
}
</style>
