<!-- eslint-disable vue/no-v-html -->
<template>
  <div
    :class="'alert alert-'+type"
    :style="{border: '2px solid '+type_c.color}"
    style="--bs-border-opacity: .5;"
  >
    <DivCell :reverse="true" :left_middle="true" :alignRight="'left'">
      <template #left>
        <PIcons
          v-if="type_c.icon_name"
          :p_icon="type_c.icon_name"
          :color="type_c.color"
          c_class="me-1"
          :size="30"
        />
      </template>
      <template #right>
        <span 
          :style="{color: type_c.color}"
        >
          {{ html ? '' : text }}
          <span
            v-if="html && !text"
            v-html="html"
          />

          <slot v-if="!text && !html" />
          <slot
            v-if="!text && !html"
            name="text"
          />
        </span>
      </template>
    </DivCell>
  </div>
</template>

<script>
import PIcons from './PIcons.vue';
import DivCell from '@/components/parts/DivCell';

const types = {
  info: {color:'#0047AB', icon_name:'PhInfo', size:'40', class:'alert-info'}, //info
  primary: {color:'#0047AB', icon_name:'PhInfo', size:'40', class:'alert-primary'}, //info
  error: {color:'red', icon_name:'PhCircleWavyWarning', size:'40', class:'alert-danger'}, //error
  danger: {color:'red', icon_name:'PhCircleWavyWarning', size:'40', class:'alert-danger'}, //error
  success: {color:'green', icon_name:'PhCheckCircle', size:'25', class:'alert-success'}, //success
  warning: {color:'#999', icon_name:'PhWarning', size:'25', class:'alert-warning'}, //warning
}

export default {
    name: "VAlert",
    components: { PIcons, DivCell },
    props: {
        closeable: { type: Boolean, default: false },
        // eslint-disable-next-line vue/require-prop-types, vue/require-default-prop
        text: { type: String },
        // eslint-disable-next-line vue/require-prop-types, vue/require-default-prop
        html: { type: String },
        type: { type: String, default: "info" },
        scrollable: { type: Boolean, default: false },
        icon: { type: Boolean, default: true },
        wrapable: { type: Boolean, default: true },
    },
    computed: {
        type_c() { return types[this.type]; }
    }
}
</script>